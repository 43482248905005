import { PHIEU_CHI_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const PhieuChiService = {};

PhieuChiService.GetAllPhieuChi = function (data) {
  return axiosInstance.post(`${PHIEU_CHI_API}/get-all-phieu-chi`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

PhieuChiService.GetPhieuChi = function (data) {
  return axiosInstance.get(`${PHIEU_CHI_API}/get-phieu-chi?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

PhieuChiService.UpsertPhieuChi = function (data) {
  return axiosInstance.put(`${PHIEU_CHI_API}/up-sert-phieu-chi`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

PhieuChiService.DeletePhieuChi = function (data) {
  return axiosInstance.delete(`${PHIEU_CHI_API}/xoa-phieu-chi?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

PhieuChiService.GetPhieuChiChiTiet = function (data) {
  return axiosInstance.get(
    `${PHIEU_CHI_API}/get-phieu-chi-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

PhieuChiService.UpsertPhieuChiChiTiet = function (data) {
  return axiosInstance.put(
    `${PHIEU_CHI_API}/up-sert-phieu-chi-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

PhieuChiService.DeletePhieuChiChiTiet = function (data) {
  return axiosInstance.delete(
    `${PHIEU_CHI_API}/xoa-phieu-chi-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

PhieuChiService.ExportPhieuFinance = function (data) {
  return axiosInstance.post(`${PHIEU_CHI_API}/export-excel`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

export default PhieuChiService;
