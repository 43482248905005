import axiosInstance from "auth/FetchInterceptor";
import {
  BAO_DUONG_API,
  CAP_PHAT_TAI_SAN_API,
  CREATE_TAI_SAN_API,
  DELETE_TAI_SAN_API,
  GET_CAP_PHAT_BY_ID_API,
  GET_TAI_SAN_API,
  EXPORT_TAI_SAN_API,
  GET_TAI_SAN_CO_THE_CAP_PHAT,
  GET_TTTH_BY_TSID,
  TAI_SAN_API,
  THU_HOI_TS_API,
  UPDATE_TAI_SAN_API,
} from "constants/ApiConstant";

const TaiSanService = {};

TaiSanService.Get = function (data) {
  return axiosInstance.post(`${GET_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.exportTaiSan = function (data) {
  return axiosInstance.post(`${EXPORT_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.Create = function (data) {
  return axiosInstance.post(`${CREATE_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_TAI_SAN_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetById = function (data) {
  return axiosInstance.get(`${TAI_SAN_API}/by-id?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetTaiSanCoTheCapPhat = function (data) {
  return axiosInstance.post(`${GET_TAI_SAN_CO_THE_CAP_PHAT}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.CreateCapPhat = function (data) {
  return axiosInstance.post(`${CAP_PHAT_TAI_SAN_API}/create`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.UpdateCapPhat = function (data) {
  return axiosInstance.put(`${CAP_PHAT_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.DeleteCapPhat = function (data) {
  return axiosInstance.put(`${CAP_PHAT_TAI_SAN_API}/delete`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetCapPhat = function (data) {
  return axiosInstance.post(`${CAP_PHAT_TAI_SAN_API}`,data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetCapPhatById = function (data) {
  return axiosInstance.get(`${GET_CAP_PHAT_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.GetThongTinThuHoiByTaiSanId = function (data) {
  return axiosInstance.post(`${GET_TTTH_BY_TSID}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.CreateThuHoi = function (data) {
  return axiosInstance.post(`${THU_HOI_TS_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetThuHoi = function (data) {
  return axiosInstance.post(`${THU_HOI_TS_API}/search`, data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetTaiSanCoTheThuHoiById = function (data) {
  return axiosInstance.get(
    `${TAI_SAN_API}/get-tai-san-co-the-thu-hoi-by-id?id=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

TaiSanService.GetMaxLoaiTaiSan = function (data) {
  return axiosInstance.get(`${TAI_SAN_API}/max-mataisan?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetQuaTrinhSuDung = function (data) {
  return axiosInstance.get(`${TAI_SAN_API}/get-qua-trinh-su-dung?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.GetTaiSanCoTheThuHoi = function (data) {
  return axiosInstance.post(
    `${TAI_SAN_API}/get-tai-san-co-the-thu-hoi-by-nhanvienid-vitritaisanid`,
    data,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

TaiSanService.GetThuHoiByid = function (data) {
  return axiosInstance.get(
    `${THU_HOI_TS_API}?id=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

TaiSanService.UpdateThuHoi = function (data) {
  return axiosInstance.put(
    `${THU_HOI_TS_API}`,data,{
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

TaiSanService.DeletePhieuThuHoi = function (id) {
  return axiosInstance.delete(
    `${THU_HOI_TS_API}?id=${id}`,{
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

TaiSanService.DeleteChiTietPhieuThuHoi = function (id) {
  return axiosInstance.delete(
    `${THU_HOI_TS_API}/chi-tiet?id=${id}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};





TaiSanService.GetThongTinTaiSanById = function (id) {
  return axiosInstance.get(`${TAI_SAN_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.updateCapPhatCTList = function (data) {
  return axiosInstance.post(`${TAI_SAN_API}/update-capphatct`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.DeleteChiTietCapPhat = function (id) {
  return axiosInstance.delete(`${TAI_SAN_API}/cap-phat/chi-tiet?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};



TaiSanService.CreateQuyDinhBaoDuong = function (data) {
  return axiosInstance.post(`${BAO_DUONG_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.UpdateQuyDinhBaoDuong = function (data) {
  return axiosInstance.put(`${BAO_DUONG_API}/quydinh`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.DeleteQuyDinhBaoDuongByID = function (id) {
  return axiosInstance.delete(`${BAO_DUONG_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.GetQuyDinhBaoDuongByID = function (id) {
  return axiosInstance.get(`${BAO_DUONG_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.GetQuyDinhBaoDuongByTaiSanID = function (id) {
  return axiosInstance.get(
    `${BAO_DUONG_API}/getallbytaisanid?id=${id}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};
TaiSanService.GetDanhSachTaiSanCanBaoDuong = function (data) {
  return axiosInstance.post(`${BAO_DUONG_API}/tai-san-can-bd`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.GetDanhSachTaiSanDangBaoDuong = function (data) {
  return axiosInstance.post(`${BAO_DUONG_API}/tai-san-dang-bd` ,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.CreateBaoDuong = function (data) {
  return axiosInstance.post(`${BAO_DUONG_API}/create` ,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};


TaiSanService.HoanThanhBaoDuong = function (data) {
  return axiosInstance.put(`${BAO_DUONG_API}/hoan-thanh-bao-duong` ,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.GetBaoDuongById = function (id) {
  return axiosInstance.get(`${BAO_DUONG_API}/get_by-id?id=${id}` ,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.SearchPhieuBaoDuong   = function (data) {
  return axiosInstance.post(`${BAO_DUONG_API}/search` ,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};


TaiSanService.UpdatePhieuBaoDuong   = function (data) {
  return axiosInstance.put(`${BAO_DUONG_API}` ,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.UpdateChiTietBaoDuong   = function (data) {
  return axiosInstance.put(`${BAO_DUONG_API}/chitiet` ,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.DeleteChiTietBaoDuong   = function (id) {
  return axiosInstance.delete(`${BAO_DUONG_API}/bao-duong/chi-tiet?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.DeletePhieutBaoDuong   = function (id) {
  return axiosInstance.delete(`${BAO_DUONG_API}/bao-duong?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

export default TaiSanService;
