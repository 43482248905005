import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import BaoMatService from "services/Asset/BaoMat/BaoMatService";


export const getAllTaiSanCoTheBaoMatByid= createAsyncThunk(
  "baoMat/getAllTaiSanCoTheBaoMatByid",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoMatService.GetAllTaiSanCoTheBaoMatByid(id);
      if(response.data.isOk) {
        if (onSuccess) onSuccess(response.data.data);
      }
      return response.data.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createBaoMat= createAsyncThunk(
    "baoMat/createBaoMat",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await BaoMatService.CreateBaoMat(data);
        if(response.data) {
          if (onSuccess) onSuccess(response.data);
        }
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const getThongTinPhieuBaoMat= createAsyncThunk(
    "baoMat/getThongTinPhieuBaoMat",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess,id } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await BaoMatService.GetThongTinPhieuBaoMat(id);
        console.log(response,"res")
        if(response.data.isOk) {
          if (onSuccess) onSuccess(response.data);
        }
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  export const getDanhSachPhieuBaoMat= createAsyncThunk(
    "baoMat/getDanhSachPhieuBaoMat",
    async (data, { rejectWithValue }) => {
      try {
        const response = await BaoMatService.GetDanhSachPhieuBaoMat(data);
        return response.data.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  export const updateChiTietBaoMat= createAsyncThunk(
    "baoMat/updateChiTietBaoMat",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await BaoMatService.UpdateChiTietBaoMat(payload);
        if (onSuccess) onSuccess(response.data);
        return response.data.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const deletePhieuBaoMat= createAsyncThunk(
    "baoMat/deletePhieuBaoMat",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await BaoMatService.DeletePhieuBaoMat(payload);
        if (onSuccess) onSuccess(response.data);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  export const deleteChiTietPhieuBaoMat= createAsyncThunk(
    "baoMat/deleteChiTietPhieuBaoMat",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await BaoMatService.DeleteChiTietPhieuBaoMat(payload);
        if (onSuccess) onSuccess(response.data);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  

const initialState = {
  loading: false,
    danhsachtaisancothebaomat:[],
    baomatdetail:{},
    danhsachphieubaomat:[]

  
};

const BaoMatSlice = createSlice({
  name: "baoMat",
  initialState,
  reducers: {
    resetdanhsachtaisancothebaomat:(state,action)=>{
      state.danhsachtaisancothebaomat=[]
    }
    ,resetbaomatdetail:(state,action)=>{
      state.baomatdetail={}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTaiSanCoTheBaoMatByid.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTaiSanCoTheBaoMatByid.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachtaisancothebaomat = action.payload
      })
      .addCase(getAllTaiSanCoTheBaoMatByid.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createBaoMat.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBaoMat.fulfilled, (state, action) => {
        state.loading = false;
        state.baomatdetail = action.payload.data
      })
      .addCase(createBaoMat.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThongTinPhieuBaoMat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThongTinPhieuBaoMat.fulfilled, (state, action) => {
        state.loading = false;
        state.baomatdetail = action.payload.data
        state.danhsachtaisancothebaomat= action.payload.data.chiTiet;
      })
      .addCase(getThongTinPhieuBaoMat.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getDanhSachPhieuBaoMat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachPhieuBaoMat.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachphieubaomat = action.payload
      })
      .addCase(getDanhSachPhieuBaoMat.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateChiTietBaoMat.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateChiTietBaoMat.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateChiTietBaoMat.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deletePhieuBaoMat.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhieuBaoMat.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deletePhieuBaoMat.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteChiTietPhieuBaoMat.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChiTietPhieuBaoMat.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteChiTietPhieuBaoMat.rejected, (state) => {
        state.loading = false;
      })
     

  },
});
export const {resetdanhsachtaisancothebaomat, resetbaomatdetail} = BaoMatSlice.actions;

export default BaoMatSlice.reducer;
