import axiosInstance from "auth/FetchInterceptor";
import { SUA_CHUA_API } from "constants/ApiConstant";

const SuaChuaService = {};

SuaChuaService.CreateBaoHong = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/bao-hong`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.CreateCanSua = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/create`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.SeachCanSua = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/search-can-sua`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.SeachDangSua = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/search-dang-sua`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.SeachTaiSan = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/tai-san`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.UpdateChiTietSuaChua = function (data) {
  return axiosInstance.put(`${SUA_CHUA_API}/sua-chua/chi-tiet`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.UpdateChiTietBaoHong = function (data) {
  return axiosInstance.put(`${SUA_CHUA_API}/bao-hong/chi-tiet`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.GetTaiSanCoTheBaoHong = function (data) {
  return axiosInstance.get(`${SUA_CHUA_API}/co-the-bao-hong?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.GetDanhSachBaoHong = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/search-bao-hong`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
SuaChuaService.GetThongTinPhieuBaoHong = function (data) {
  return axiosInstance.get(`${SUA_CHUA_API}/bao-hong?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.DeletePhieuBaoHong = function (id) {
  return axiosInstance.delete(`${SUA_CHUA_API}/bao-hong?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.DeleteChiTietPhieuBaoHong = function (id) {
  return axiosInstance.delete(`${SUA_CHUA_API}/bao-hong/chi-tiet?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};


SuaChuaService.UpdateHoanThanhSuaChua = function (data) {
  return axiosInstance.put(`${SUA_CHUA_API}/hoan-thanh`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.GetAllHangMuc = function (id) {
  return axiosInstance.get(`${SUA_CHUA_API}/hang-muc?branchId=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
SuaChuaService.LichSuSuaChua = function (id) {
  return axiosInstance.get(`${SUA_CHUA_API}/lich-su?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.GetDanhSachPhieuSuaChua = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/phieu`, data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.GetChiTietPhieuSuaChua = function (id) {
  return axiosInstance.get(`${SUA_CHUA_API}?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.DeletePhieuBaoSuaChua = function (id) {
  return axiosInstance.delete(`${SUA_CHUA_API}?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.DeleteChiTietBaoSuaChua = function (id) {
  return axiosInstance.delete(`${SUA_CHUA_API}/chi-tiet?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};


export default SuaChuaService;
