import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import PhieuChiService from "services/Finance/PhieuChiService";

export const GetAllPhieuChi = createAsyncThunk(
  "PhieuChi/GetAllPhieuChi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PhieuChiService.GetAllPhieuChi(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetPhieuChi = createAsyncThunk(
  "PhieuChi/GetPhieuChi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PhieuChiService.GetPhieuChi(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertPhieuChi = createAsyncThunk(
  "PhieuChi/UpsertPhieuChi",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuChiService.UpsertPhieuChi(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeletePhieuChi = createAsyncThunk(
  "PhieuChi/DeletePhieuChi",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuChiService.DeletePhieuChi(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetPhieuChiChiTiet = createAsyncThunk(
  "PhieuChi/GetPhieuChiChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PhieuChiService.GetPhieuChiChiTiet(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertPhieuChiChiTiet = createAsyncThunk(
  "PhieuChi/UpsertPhieuChiChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuChiService.UpsertPhieuChiChiTiet(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeletePhieuChiChiTiet = createAsyncThunk(
  "PhieuChi/DeletePhieuChiChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuChiService.DeletePhieuChiChiTiet(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const ExportPhieuChi = createAsyncThunk(
  "dinhKhoan/ExportPhieuChi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuChiService.ExportPhieuFinance(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  PhieuChiList: [],
  PhieuChiDetail: {},
  PhieuChiChiTietList: [],
};

const phieuChiTienSlice = createSlice({
  name: "phieuChi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetPhieuChiChiTiet
      .addCase(GetPhieuChiChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetPhieuChiChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.PhieuChiChiTietList = action.payload;
      })
      .addCase(GetPhieuChiChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetPhieuChi
      .addCase(GetPhieuChi.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetPhieuChi.fulfilled, (state, action) => {
        state.loading = false;
        state.PhieuChiDetail = action.payload;
      })
      .addCase(GetPhieuChi.rejected, (state) => {
        state.loading = false;
      })
      //GetAllPhieuChi
      .addCase(GetAllPhieuChi.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllPhieuChi.fulfilled, (state, action) => {
        state.loading = false;
        state.PhieuChiList = action.payload;
      })
      .addCase(GetAllPhieuChi.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = phieuChiTienSlice.actions;

export default phieuChiTienSlice.reducer;
