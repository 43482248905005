import { DE_NGHI_CHI_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const DeNghiChiTienService = {};

DeNghiChiTienService.GetAllDeNghiChiTien = function (data) {
  return axiosInstance.post(`${DE_NGHI_CHI_API}/get-all-de-nghi-chi`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

DeNghiChiTienService.GetDeNghiChiTien = function (data) {
  return axiosInstance.get(`${DE_NGHI_CHI_API}/get-de-nghi-chi?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

DeNghiChiTienService.UpsertDeNghiChiTien = function (data) {
  return axiosInstance.put(`${DE_NGHI_CHI_API}/up-sert-de-nghi-chi`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

DeNghiChiTienService.DeleteDeNghiChiTien = function (data) {
  return axiosInstance.delete(`${DE_NGHI_CHI_API}/xoa-de-nghi-chi?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

DeNghiChiTienService.IsTongHopTrinh = function (data) {
  return axiosInstance.post(
    `${DE_NGHI_CHI_API}/trinh-de-nghi-thanh-toan`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiChiTienService.GetDeNghiChiTienChiTiet = function (data) {
  return axiosInstance.get(
    `${DE_NGHI_CHI_API}/get-de-nghi-chi-chi-tiet?id=${data.id}&IsChi=${data.isChi}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiChiTienService.GetChiDeNghiChiTienChiTiet = function (data) {
  return axiosInstance.get(
    `${DE_NGHI_CHI_API}/get-chi-de-nghi-chi-chi-tiet?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiChiTienService.UpsertDeNghiChiTienChiTiet = function (data) {
  return axiosInstance.put(
    `${DE_NGHI_CHI_API}/up-sert-de-nghi-chi-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiChiTienService.DeleteDeNghiChiTienChiTiet = function (data) {
  return axiosInstance.delete(
    `${DE_NGHI_CHI_API}/xoa-de-nghi-chi-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiChiTienService.ExportPhieuFinance = function (data) {
  return axiosInstance.post(`${DE_NGHI_CHI_API}/export-excel`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

export default DeNghiChiTienService;
